import * as React from 'react';
import classnames from 'classnames';
import { IUnsupportedSocialAuthMessageProps } from '../MemberLoginDialog.types';
import { ReactComponent as GoogleIcon } from './assets/google.svg';
import { ReactComponent as FacebookIcon } from './assets/facebook.svg';
import { MemberLoginDialogTranslationKeys as keys, testIds } from './utils';
import style from './style/style.scss';

export const UnsupportedSocialAuthMessage: React.FC<IUnsupportedSocialAuthMessageProps> =
  ({
    isSocialLoginFacebookEnabled,
    isSocialLoginGoogleEnabled,
    translate,
    mode,
    styleMode = 'dialog',
  }) => {
    const [isToastVisible, setToastVisibile] = React.useState(false);

    const copyUrlToClipboard = () => {
      const dummyElement = document.createElement('input');
      const currURL = window.location.href;
      document.body.appendChild(dummyElement);
      dummyElement.value = currURL;
      dummyElement.select();
      document.execCommand('copy');
      document.body.removeChild(dummyElement);
      setToastVisibile(true);
      setTimeout(() => {
        setToastVisibile(false);
      }, 3000);
    };

    const getMessagePrefix = () => {
      switch (mode) {
        case 'login':
          return translate(
            keys.inAppBrowserSocialAuthLoginMessagePrefix,
            'To Login with',
          );
        case 'signup':
          return translate(
            keys.inAppBrowserSocialAuthSignUpMessagePrefix,
            'To Sign Up with',
          );
        default:
          return '';
      }
    };

    const authMessageStyle = {
      dialog: style.dialogSocialAuthUnsupportedMessage,
      custom: style.customSocialAuthUnsupportedMessage,
    };

    const linkCopied = translate(keys.linkCopied, 'Link Copied');
    const copyLinkText = translate(keys.copyLinkText, 'Copy link');
    const facebookLabel = translate(keys.facebookLabel, 'Facebook');
    const googleLabel = translate(keys.googleLabel, 'Google');
    const inAppBrowserSocialAuthMessageOr = translate(
      keys.inAppBrowserSocialAuthMessageOr,
      'or',
    );
    const inAppBrowserSocialAuthMessageSuffix = translate(
      keys.inAppBrowserSocialAuthLoginMessageSuffix,
      'copy this page link and open it in another browser.',
    );
    const inAppBrowserSocialAuthMessagePrefix = getMessagePrefix();

    const authMethodsTranslations: { [key: string]: string } = {
      google: googleLabel,
      facebook: facebookLabel,
      googlefacebook: `${googleLabel} ${inAppBrowserSocialAuthMessageOr} ${facebookLabel}`,
    };

    const authMethodsTranslationKey = `${
      isSocialLoginGoogleEnabled ? 'google' : ''
    }${isSocialLoginFacebookEnabled ? 'facebook' : ''}`;
    const availableAuths = authMethodsTranslations[authMethodsTranslationKey];
    const inAppMessageTranslation = availableAuths
      ? `${inAppBrowserSocialAuthMessagePrefix} ${availableAuths} ${inAppBrowserSocialAuthMessageSuffix}`
      : '';

    return (
      <>
        <div
          data-testid={testIds.socialAuthMessage}
          className={classnames(
            style.inAppBrowserSocialAuth,
            authMessageStyle[styleMode],
          )}
        >
          <div className={classnames(style.socialAuthIcons)}>
            {isSocialLoginGoogleEnabled && (
              <GoogleIcon className={classnames(style.socialIcon)} />
            )}
            {isSocialLoginFacebookEnabled && (
              <FacebookIcon className={classnames(style.socialIcon)} />
            )}
          </div>
          <div className={classnames(style.inAppSocialAuthText)}>
            {inAppMessageTranslation}
            &nbsp;
            <button
              type="button"
              className={classnames(style.copyLink)}
              onClick={copyUrlToClipboard}
              data-testid={testIds.copyLinkButton}
            >
              {copyLinkText}
            </button>
          </div>
        </div>
        <div
          className={classnames(style.hiddenLinkCopiedToast, {
            [style.visiblelinkCopiedToast]: isToastVisible,
          })}
        >
          {linkCopied}
        </div>
      </>
    );
  };
